<template>
    <ValidationObserver
        ref="validationObserver"
        class="datamap-builder"
    >
        <MToolbox
            v-if="canManage"
            data-cy="operationsToolbox"
        />
        <div class="datamap-canvas">
            <MBuilderToolbar
                @save="save"
                @run-preview="runDatamapPreview"
            />

            <div
                v-if="!loading"
                class="row container-fluid w-auto"
            >
                <MWorkspace
                    :read-only="isObsolete(version.dataMap) || isPublished(version) || !canManage"
                />

                <MDataMapPreview
                    :resulting-object="resultingObject"
                />
            </div>

            <div
                v-else
                class="loading-indicator"
                data-cy="loadingIndicator"
            >
                <FontAwesomeIcon
                    far
                    icon="spinner"
                    pulse
                />
                loading...
            </div>
        </div>

        <RouterView />
    </ValidationObserver>
</template>

<script>
    import filterMixin from '@imt/vue-toolbox/src/mixins/filters';
    import toastMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import versionMixin from '@imt/vue-versioning/src/mixins/versioning';
    import {mapActions, mapMutations, mapState} from 'vuex';

    import MBuilderToolbar from '../components/builder/MBuilderToolbar.vue';
    import MDataMapPreview from '../components/builder/MDataMapPreview.vue';
    import MToolbox from '../components/builder/MToolbox.vue';
    import MWorkspace from '../components/builder/MWorkspace.vue';
    import authMixin from '../mixins/auth';

    export default {
        name: 'DataMapBuilder',
        components: {
            MBuilderToolbar,
            MDataMapPreview,
            MToolbox,
            MWorkspace,
        },
        mixins: [
            authMixin,
            filterMixin,
            toastMixin,
            versionMixin,
        ],
        metaInfo: {
            title: 'Data Map Builder',
        },
        data() {
            return {
                resultingObject: {},
                loading: true,
            };
        },
        computed: {
            ...mapState('builder', [
                'version',
            ]),
        },
        async created() {
            await this.init();
        },
        methods: {
            async init() {
                this.loading = true;

                try {
                    await this.fetchVersion(this.$route.params.versionId);
                    await this.fetchOperations();
                    this.loading = false;
                } catch (e) {
                    this.error('Failed to fetch Data Map', 'Data Map');
                }

                await this.SET_DATA({
                    type: 'saving',
                    data: false,
                });
            },
            async runDatamapPreview() {
                let valid = await this.$refs.validationObserver.validate();
                if (valid) {
                    if (localStorage.getItem(`loadedObject${this.$route.params.versionId}`)) {
                        try {
                            await this.save();
                            let object = JSON.parse(localStorage.getItem(`loadedObject${this.$route.params.versionId}`)),
                                response = await this.runDatamap({
                                    code: this.version.dataMap.code,
                                    object,
                                    versionId: this.version.id
                                });

                            this.resultingObject = response.resultingObject;
                            this.success('Preview Loaded');
                        } catch (error) {
                            let errors = [];
                            error.response.data.errors.forEach((e) => {
                                errors = errors + `${e.detail}\n`;
                            });

                            this.error(errors);
                        }
                    } else {
                        await this.$router.push({name: 'admin.datamaps.load'});
                    }
                } else {
                    this.error('Error while creating datamap!\nPlease verify your input below.', 'Save DataMap');
                }
            },
            async save() {
                let valid = await this.$refs.validationObserver.validate();

                if (valid) {
                    try {
                        await this.saveDataMapOperations();
                        await this.saveVersion();
                        this.success('Operation saved successfully.', 'Save Operation');
                    } catch (e) {
                        this.error('Error saving operation, please try again.', 'Save Operation');
                    }

                    await this.init();
                } else {
                    this.error('Error while creating datamap!\nPlease verify your input below.', 'Save DataMap');
                }
            },
            ...mapActions('mapping', [
                'fetchDataMap',
            ]),
            ...mapActions('builder', [
                'fetchOperations',
                'fetchVersion',
                'runDatamap',
                'saveDataMapOperations',
                'saveVersion',
            ]),
            ...mapMutations('builder', [
                'SET_DATA',
            ]),
        },
    };
</script>

<style
    lang="sass"
>
    .datamap-builder
        display: flex
        flex-direction: row
        width: 100%
        height: calc(100vh - 72px)

        .datamap-canvas
            padding: 0.5rem
            width: 100%
            overflow-y: auto
            overflow-x: hidden

            .builder-toolbar
                width: calc(100% + 1rem)
                margin: -0.5rem -0.5rem 0.5rem -0.5rem
                top: -0.5rem

    .footer
        display: none !important

    .loading-indicator
        font-size: 2rem
        width: 100%
        text-align: center
        padding: 3rem
        display: block
</style>
